<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import axios from 'axios'

export default {
   name: 'ScrapRepairIzinLama',
   components: {},
   data() {
      return {
         request: null,
      }
   },
   computed: {
      ...mapState({
         myData: state => state.OssScrapRepairIzinLama.data,
         myCount: state => state.OssScrapRepairIzinLama.countAll,
         myCurrent: state => state.OssScrapRepairIzinLama.current,
         mySuccess: state => state.OssScrapRepairIzinLama.success,
         myFailed: state => state.OssScrapRepairIzinLama.failed,
         myState: state => state.OssScrapRepairIzinLama.runningState,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      current: {
         get() {
            return this.myCurrent
         },
         set(value) {
            let newVal

            if (value) {
               newVal = value
            } else {
               newVal = 0
            }

            this.updateCurrent(parseInt(newVal, 10))
         },
      },
   },
   watch: {
      // isLoading(val) {
      //    if (val) {
      //       this.$swal({ text: 'Loading', allowOutsideClick: false })
      //       this.$swal.showLoading()
      //    } else {
      //       this.$swal.close()
      //    }
      // },
   },
   mounted() {
      this.getData()
         .then(result => {
            console.log(result)
         })
         .catch(error => {
            console.log(error)
         })
   },
   methods: {
      ...mapActions({
         getData: 'OssScrapRepairIzinLama/getData',
         runScrap: 'OssScrapRepairIzinLama/runScrap',
      }),
      ...mapMutations({
         updateCurrent: 'OssScrapRepairIzinLama/SET_CURRENT',
         setState: 'OssScrapRepairIzinLama/SET_STATE',
         setCancelToken: 'OssScrapRepairIzinLama/SET_CANCEL_TOKEN',
      }),
      run() {
         if (!this.myState) {
            return
         }

         console.log(this.myData[this.myCurrent].id_permohonan_izin)
         this.runScrap(this.myData[this.myCurrent].id_permohonan_izin).then(() => {
            if (this.myCurrent < this.myCount) {
               this.run()
               console.log('lanjut')
            } else {
               this.setState(false)
            }
         })
      },
      onStart() {
         const axiosSource = axios.CancelToken.source()
         this.request = { cancel: axiosSource.cancel }
         this.setCancelToken(axiosSource.token)
         this.setState(true)
         this.run()
      },
      onStop() {
         this.setState(false)
         this.request.cancel()
      },
   },
}
</script>

<template>
   <b-row>
      <b-col
         cols="12"
         xl="4"
         lg="4"
         md="4"
      >
         <b-card title="Jumlah">
            <b-row>
               <b-col
                  cols="21"
                  xl="12"
                  class="mb-2"
               >
                  <div class="d-flex align-items-center mr-2">
                     <b-avatar
                        variant="light-primary"
                        rounded
                     >
                        <feather-icon
                           icon="DollarSignIcon"
                           size="18"
                        />
                     </b-avatar>
                     <div class="ml-1">
                        <h5 class="mb-0">
                           {{ myCount }}
                        </h5>
                        <small>Total NIB</small>
                     </div>
                  </div>
               </b-col>
               <b-col
                  cols="21"
                  xl="12"
                  class="mb-2"
               >
                  <div class="d-flex align-items-center">
                     <b-avatar
                        variant="light-primary"
                        rounded
                     >
                        <feather-icon
                           icon="TrendingUpIcon"
                           size="18"
                        />
                     </b-avatar>
                     <div class="ml-1">
                        <h5 class="mb-0">
                           {{ myCount }}
                        </h5>
                        <small>Total NIB (Source)</small>
                     </div>
                  </div>
               </b-col>
            </b-row>
         </b-card>
         <b-card title="Status">
            <b-row>
               <b-col
                  cols="21"
                  xl="12"
                  class="mb-2"
               >
                  <div class="d-flex align-items-center mr-2">
                     <b-avatar
                        variant="light-primary"
                        rounded
                     >
                        <feather-icon
                           icon="DollarSignIcon"
                           size="18"
                        />
                     </b-avatar>
                     <div class="ml-1">
                        <h5 class="mb-0">
                           {{ myCurrent }}
                        </h5>
                        <small>Current / Posisi</small>
                     </div>
                  </div>
               </b-col>
               <b-col
                  cols="21"
                  xl="12"
                  class="mb-2"
               >
                  <div class="d-flex align-items-center">
                     <b-avatar
                        variant="light-success"
                        rounded
                     >
                        <feather-icon
                           icon="TrendingUpIcon"
                           size="18"
                        />
                     </b-avatar>
                     <div class="ml-1">
                        <h5 class="mb-0">
                           {{ mySuccess }}
                        </h5>
                        <small>Sukses</small>
                     </div>
                  </div>
               </b-col>
               <b-col
                  cols="21"
                  xl="12"
                  class="mb-2"
               >
                  <div class="d-flex align-items-center">
                     <b-avatar
                        variant="light-danger"
                        rounded
                     >
                        <feather-icon
                           icon="TrendingUpIcon"
                           size="18"
                        />
                     </b-avatar>
                     <div class="ml-1">
                        <h5 class="mb-0">
                           {{ myFailed }}
                        </h5>
                        <small>Error</small>
                     </div>
                  </div>
               </b-col>
               <b-col
                  md="12"
                  class="mb-1"
               >
                  <b-form-input
                     v-model="current"
                     :disabled="myState"
                     type="number"
                     placeholder="Enter Posisi"
                  />
               </b-col>
               <b-col
                  md="12"
                  class="mb-1"
               >
                  <b-button
                     :disabled="myState"
                     variant="secondary"
                     block
                     @click="onStart"
                  >
                     <span
                        v-show="isLoading"
                        class="spinner-border spinner-border-sm"
                     />
                     Start
                  </b-button>
               </b-col>
               <b-col
                  md="12"
                  class="mb-1"
               >
                  <b-button
                     :disabled="!myState"
                     variant="danger"
                     block
                     @click="onStop"
                  >
                     Stop
                  </b-button>
               </b-col>
            </b-row>
         </b-card>
      </b-col>
      <b-col
         cols="12"
         md="8"
         xl="8"
         lg="8"
      >
         <b-card title="Action">
            <b-row>
               <b-col md="12">
                  <div>
                     <!-- <label>Progress label with precision</label> -->
                     <b-progress
                        :value="myCurrent"
                        :max="myCount"
                        precision="2"
                        show-progress
                        variant="primary"
                        class="progress-bar-warning"
                     />
                  </div>
               </b-col>
            </b-row>
         </b-card>
      </b-col>
   </b-row>
</template>
<style>
</style>
